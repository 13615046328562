import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Menu, X, ShoppingBag, ChevronDown, Eye } from 'lucide-react';
import Footer from '../components/Footer';
import Breadcrumbs from '../components/Breadcrumbs';

// Demo products data
const products = {
  mens: [
    {
      id: 'm1',
      name: 'Leather Motorcycle Jacket',
      description: 'Premium leather jacket with protective padding',
      price: 299.99,
      image: 'https://images.unsplash.com/photo-1520975954732-35dd22299614?auto=format&fit=crop&q=80&w=600',
      sizes: ['S', 'M', 'L', 'XL', '2XL'],
    },
    {
      id: 'm2',
      name: 'Riding Jeans',
      description: 'Reinforced denim with Kevlar lining',
      price: 149.99,
      image: 'https://images.unsplash.com/photo-1584865288642-42078afe6942?auto=format&fit=crop&q=80&w=600',
      sizes: ['30', '32', '34', '36', '38'],
    },
    {
      id: 'm3',
      name: 'Graphic T-Shirt',
      description: 'Cotton blend with custom motorcycle design',
      price: 29.99,
      image: 'https://images.unsplash.com/photo-1583743814966-8936f5b7be1a?auto=format&fit=crop&q=80&w=600',
      sizes: ['S', 'M', 'L', 'XL', '2XL'],
    },
  ],
  womens: [
    {
      id: 'w1',
      name: 'Women\'s Leather Jacket',
      description: 'Stylish leather jacket with protective features',
      price: 289.99,
      image: 'https://images.unsplash.com/photo-1551028719-00167b16eac5?auto=format&fit=crop&q=80&w=600',
      sizes: ['XS', 'S', 'M', 'L', 'XL'],
    },
    {
      id: 'w2',
      name: 'Protective Leggings',
      description: 'High-waisted leggings with reinforced panels',
      price: 119.99,
      image: 'https://images.unsplash.com/photo-1506629082955-511b1aa562c8?auto=format&fit=crop&q=80&w=600',
      sizes: ['XS', 'S', 'M', 'L', 'XL'],
    },
    {
      id: 'w3',
      name: 'Fitted T-Shirt',
      description: 'Soft cotton blend with custom design',
      price: 27.99,
      image: 'https://images.unsplash.com/photo-1503342394128-c104d54dba01?auto=format&fit=crop&q=80&w=600',
      sizes: ['XS', 'S', 'M', 'L', 'XL'],
    },
  ],
};

function ApparelCategory() {
  const { category = 'mens' } = useParams();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<null | any>(null);
  const [selectedSize, setSelectedSize] = useState('');
  const [quantity, setQuantity] = useState(1);

  const categoryProducts = products[category as keyof typeof products] || [];
  const categoryTitle = category === 'mens' ? "Men's Collection" : "Women's Collection";

  const openQuickView = (e: React.MouseEvent, product: any) => {
    e.preventDefault();
    setSelectedProduct(product);
    setSelectedSize('');
    setQuantity(1);
    setIsQuickViewOpen(true);
  };

  const handleAddToCart = () => {
    if (!selectedSize) {
      alert('Please select a size');
      return;
    }
    // Add to cart logic here
    console.log('Added to cart:', { ...selectedProduct, size: selectedSize, quantity });
    setIsQuickViewOpen(false);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navigation */}
      <nav className="fixed w-full bg-white shadow-sm z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-20">
            <div className="flex items-center h-full py-2">
              <Link to="/" className="w-[200px]">
                <img
                  src="https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/Logos-for-shine.gif"
                  alt="2nd Amendment Cycles"
                  className="w-full h-full object-contain"
                />
              </Link>
            </div>
            
            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-8">
              <Link to="/shop" className="text-gray-900 hover:text-[#C3A15F] transition-colors">
                Shop All
              </Link>
              <Link to="/shop/apparel/mens" className="text-gray-900 hover:text-[#C3A15F] transition-colors">
                Men's
              </Link>
              <Link to="/shop/apparel/womens" className="text-gray-900 hover:text-[#C3A15F] transition-colors">
                Women's
              </Link>
              <Link to="/shop/new" className="text-gray-900 hover:text-[#C3A15F] transition-colors">
                New Arrivals
              </Link>
              <Link to="/shop/sale" className="text-[#cf2e2e] hover:text-[#cf2e2e]/80 transition-colors">
                Sale
              </Link>
              <button className="relative p-2">
                <ShoppingBag className="w-6 h-6 text-gray-900" />
                <span className="absolute top-0 right-0 -mt-1 -mr-1 bg-[#cf2e2e] text-white text-xs w-5 h-5 rounded-full flex items-center justify-center">
                  0
                </span>
              </button>
            </div>

            {/* Mobile Navigation */}
            <div className="md:hidden flex items-center space-x-4">
              <button className="relative p-2">
                <ShoppingBag className="w-6 h-6 text-gray-900" />
                <span className="absolute top-0 right-0 -mt-1 -mr-1 bg-[#cf2e2e] text-white text-xs w-5 h-5 rounded-full flex items-center justify-center">
                  0
                </span>
              </button>
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="text-gray-900"
              >
                {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden bg-white border-t">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <Link
                to="/shop"
                className="block px-3 py-2 text-gray-900 hover:bg-gray-50 hover:text-[#C3A15F]"
              >
                Shop All
              </Link>
              <Link
                to="/shop/apparel/mens"
                className="block px-3 py-2 text-gray-900 hover:bg-gray-50 hover:text-[#C3A15F]"
              >
                Men's
              </Link>
              <Link
                to="/shop/apparel/womens"
                className="block px-3 py-2 text-gray-900 hover:bg-gray-50 hover:text-[#C3A15F]"
              >
                Women's
              </Link>
              <Link
                to="/shop/new"
                className="block px-3 py-2 text-gray-900 hover:bg-gray-50 hover:text-[#C3A15F]"
              >
                New Arrivals
              </Link>
              <Link
                to="/shop/sale"
                className="block px-3 py-2 text-[#cf2e2e] hover:bg-gray-50"
              >
                Sale
              </Link>
            </div>
          </div>
        )}
      </nav>

      {/* Main Content */}
      <div className="pt-24">
        {/* Breadcrumbs */}
        <div className="bg-white border-b">
          <div className="max-w-7xl mx-auto px-4 py-4">
            <Breadcrumbs />
          </div>
        </div>

        {/* Category Header */}
        <div className="bg-gray-900 text-white py-12">
          <div className="max-w-7xl mx-auto px-4">
            <h1 className="text-4xl font-bold mb-4">{categoryTitle}</h1>
            <p className="text-gray-300">
              Quality apparel designed for riders who demand style and protection.
            </p>
          </div>
        </div>

        {/* Products Grid */}
        <div className="max-w-7xl mx-auto px-4 py-12">
          <div className="grid md:grid-cols-3 gap-6">
            {categoryProducts.map((product) => (
              <Link
                to={`/shop/product/${product.id}`}
                key={product.id}
                className="bg-white rounded-lg shadow-sm overflow-hidden group hover:shadow-md transition-shadow"
              >
                <div className="relative aspect-square overflow-hidden">
                  <img
                    src={product.image}
                    alt={product.name}
                    className="absolute inset-0 w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                  />
                  <div className="absolute inset-0 bg-black/0 group-hover:bg-black/20 transition-colors">
                    <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                      <button
                        onClick={(e) => openQuickView(e, product)}
                        className="bg-white text-gray-900 p-3 rounded-full transform translate-y-4 group-hover:translate-y-0 transition-transform hover:bg-gray-100"
                      >
                        <Eye size={20} />
                      </button>
                    </div>
                    <span className="absolute bottom-4 right-4 bg-[#cf2e2e] text-white px-4 py-2 rounded opacity-0 group-hover:opacity-100 transition-opacity">
                      View Details
                    </span>
                  </div>
                </div>
                <div className="p-4">
                  <h3 className="font-medium text-gray-900 mb-2">{product.name}</h3>
                  <p className="text-gray-500 text-sm mb-4">{product.description}</p>
                  <span className="text-lg font-bold text-gray-900">${product.price.toFixed(2)}</span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>

      {/* Quick View Modal */}
      {isQuickViewOpen && selectedProduct && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg max-w-4xl w-full mx-4 max-h-[90vh] overflow-y-auto">
            <div className="p-6">
              <div className="flex justify-between items-start mb-6">
                <h3 className="text-2xl font-bold text-gray-900">{selectedProduct.name}</h3>
                <button
                  onClick={() => setIsQuickViewOpen(false)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <X size={24} />
                </button>
              </div>
              <div className="grid md:grid-cols-2 gap-8">
                <div className="aspect-square relative rounded-lg overflow-hidden">
                  <img
                    src={selectedProduct.image}
                    alt={selectedProduct.name}
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                </div>
                <div>
                  <p className="text-gray-600 mb-4">{selectedProduct.description}</p>
                  <p className="text-3xl font-bold text-gray-900 mb-6">
                    ${selectedProduct.price.toFixed(2)}
                  </p>
                  
                  {/* Size Selection */}
                  <div className="mb-6">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Size
                    </label>
                    <div className="grid grid-cols-5 gap-2">
                      {selectedProduct.sizes.map((size: string) => (
                        <button
                          key={size}
                          onClick={() => setSelectedSize(size)}
                          className={`py-2 text-center rounded border ${
                            selectedSize === size
                              ? 'border-[#C3A15F] bg-[#C3A15F] text-white'
                              : 'border-gray-300 hover:border-[#C3A15F]'
                          }`}
                        >
                          {size}
                        </button>
                      ))}
                    </div>
                  </div>
                  
                  {/* Quantity Selection */}
                  <div className="mb-6">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Quantity
                    </label>
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={() => setQuantity(Math.max(1, quantity - 1))}
                        className="w-10 h-10 rounded border border-gray-300 flex items-center justify-center hover:border-[#C3A15F]"
                      >
                        -
                      </button>
                      <input
                        type="number"
                        min="1"
                        value={quantity}
                        onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value) || 1))}
                        className="w-20 h-10 text-center border border-gray-300 rounded"
                      />
                      <button
                        onClick={() => setQuantity(quantity + 1)}
                        className="w-10 h-10 rounded border border-gray-300 flex items-center justify-center hover:border-[#C3A15F]"
                      >
                        +
                      </button>
                    </div>
                  </div>
                  
                  <div className="space-y-4">
                    <button
                      onClick={handleAddToCart}
                      className="w-full px-6 py-3 bg-[#cf2e2e] text-white rounded-lg hover:bg-[#cf2e2e]/90 transition-colors"
                    >
                      Add to Cart
                    </button>
                    <Link
                      to={`/shop/product/${selectedProduct.id}`}
                      className="block w-full px-6 py-3 bg-gray-900 text-white rounded-lg hover:bg-gray-800 transition-colors text-center"
                    >
                      View Full Details
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default ApparelCategory;