import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Menu, 
  X, 
  ShoppingBag, 
  ChevronDown, 
  ChevronLeft, 
  ChevronRight,
  SlidersHorizontal,
  ArrowUpDown,
  Search,
  Eye
} from 'lucide-react';
import Footer from '../components/Footer';
import Breadcrumbs from '../components/Breadcrumbs';

// Demo featured parts data
const featuredParts = [
  {
    id: 1,
    name: 'Premium Chrome Handlebar',
    description: 'High-quality chrome handlebar with ergonomic design for maximum comfort',
    price: 199.99,
    image: 'https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/DSC_7414.jpg'
  },
  {
    id: 2,
    name: 'LED Headlight Assembly',
    description: 'Advanced LED headlight with improved visibility and modern design',
    price: 299.99,
    image: 'https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/DSC_7413.jpg'
  },
  {
    id: 3,
    name: 'Performance Air Filter',
    description: 'High-flow air filter for improved engine performance',
    price: 79.99,
    image: 'https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/DSC_7364.jpg'
  }
];

// Demo parts grid data
const partsGrid = Array(12).fill(null).map((_, index) => ({
  id: index + 4,
  name: `Custom Part ${index + 1}`,
  description: 'High-quality motorcycle part',
  price: 99.99 + index * 10,
  image: 'https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/DSC_7414.jpg'
}));

function Parts() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);
  const [selectedPart, setSelectedPart] = useState<null | typeof featuredParts[0]>(null);

  // Auto-advance slider
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % featuredParts.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % featuredParts.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + featuredParts.length) % featuredParts.length);
  };

  const openQuickView = (part: typeof featuredParts[0]) => {
    setSelectedPart(part);
    setIsQuickViewOpen(true);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navigation */}
      <nav className="fixed w-full bg-white shadow-sm z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-20">
            <div className="flex items-center">
              <Link to="/" className="w-[200px]">
                <img
                  src="https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/2nd%20Amendment_shiny_horizontal-03.svg"
                  alt="2nd Amendment Cycles"
                  className="w-full h-full object-contain logo-glow"
                />
              </Link>
            </div>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-8">
              <Link to="/shop" className="text-gray-900 hover:text-[#C3A15F]">Shop All</Link>
              <Link to="/shop/new" className="text-gray-900 hover:text-[#C3A15F]">New Arrivals</Link>
              <Link to="/shop/sale" className="text-[#cf2e2e] hover:text-[#cf2e2e]/80">Sale</Link>
              <button className="relative">
                <ShoppingBag className="w-6 h-6 text-gray-900" />
                <span className="absolute -top-1 -right-1 bg-[#cf2e2e] text-white text-xs w-5 h-5 rounded-full flex items-center justify-center">
                  0
                </span>
              </button>
            </div>

            {/* Mobile menu button */}
            <div className="md:hidden flex items-center">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="text-gray-900"
              >
                {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <div className="pt-20">
        {/* Breadcrumbs */}
        <div className="bg-white border-b">
          <div className="max-w-7xl mx-auto px-4 py-4">
            <Breadcrumbs />
          </div>
        </div>

        {/* Featured Parts Slider */}
        <div className="relative bg-gray-900">
          <div className="max-w-7xl mx-auto px-4">
            <h2 className="text-3xl font-bold text-white pt-8 pb-4">Featured Parts</h2>
            <div className="relative overflow-hidden">
              <div 
                className="flex transition-transform duration-500 ease-in-out"
                style={{ transform: `translateX(-${currentSlide * 100}%)` }}
              >
                {featuredParts.map((part) => (
                  <div key={part.id} className="w-full flex-shrink-0">
                    <div className="grid md:grid-cols-2 gap-8 py-8">
                      <div className="relative aspect-[4/3] overflow-hidden rounded-lg">
                        <img
                          src={part.image}
                          alt={part.name}
                          className="absolute inset-0 w-full h-full object-cover"
                        />
                      </div>
                      <div className="flex flex-col justify-center text-white">
                        <h3 className="text-3xl font-bold mb-4">{part.name}</h3>
                        <p className="text-gray-300 mb-6">{part.description}</p>
                        <p className="text-2xl font-bold mb-6">${part.price.toFixed(2)}</p>
                        <div className="flex space-x-4">
                          <button 
                            className="px-6 py-3 bg-[#C3A15F] text-white rounded-lg hover:bg-[#C3A15F]/90 transition-colors"
                            onClick={() => openQuickView(part)}
                          >
                            Quick View
                          </button>
                          <button className="px-6 py-3 bg-[#cf2e2e] text-white rounded-lg hover:bg-[#cf2e2e]/90 transition-colors">
                            Add to Cart
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              
              {/* Slider Controls */}
              <button
                onClick={prevSlide}
                className="absolute left-4 top-1/2 -translate-y-1/2 w-10 h-10 bg-white/10 hover:bg-white/20 rounded-full flex items-center justify-center text-white transition-colors"
              >
                <ChevronLeft size={24} />
              </button>
              <button
                onClick={nextSlide}
                className="absolute right-4 top-1/2 -translate-y-1/2 w-10 h-10 bg-white/10 hover:bg-white/20 rounded-full flex items-center justify-center text-white transition-colors"
              >
                <ChevronRight size={24} />
              </button>
              
              {/* Slide Indicators */}
              <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex space-x-2">
                {featuredParts.map((_, index) => (
                  <button
                    key={index}
                    onClick={() => setCurrentSlide(index)}
                    className={`w-2 h-2 rounded-full transition-colors ${
                      currentSlide === index ? 'bg-white' : 'bg-white/50'
                    }`}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Filters and Sort */}
        <div className="bg-white border-b">
          <div className="max-w-7xl mx-auto px-4 py-4">
            <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
              <div className="flex items-center space-x-4">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search parts..."
                    className="pl-10 pr-4 py-2 border rounded-lg w-64 focus:outline-none focus:ring-2 focus:ring-[#C3A15F]"
                  />
                  <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
                </div>
                <button className="flex items-center space-x-2 px-4 py-2 border rounded-lg hover:bg-gray-50">
                  <SlidersHorizontal size={20} />
                  <span>Filter</span>
                </button>
              </div>
              <div className="flex items-center space-x-4">
                <button className="flex items-center space-x-2 px-4 py-2 border rounded-lg hover:bg-gray-50">
                  <ArrowUpDown size={20} />
                  <span>Sort by</span>
                  <ChevronDown size={16} />
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Parts Grid */}
        <div className="max-w-7xl mx-auto px-4 py-12">
          <div className="grid md:grid-cols-3 lg:grid-cols-4 gap-6">
            {partsGrid.map((part) => (
              <div key={part.id} className="bg-white rounded-lg shadow-sm overflow-hidden group">
                <div className="relative aspect-square overflow-hidden">
                  <img
                    src={part.image}
                    alt={part.name}
                    className="absolute inset-0 w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                  />
                  <div className="absolute inset-0 bg-black/0 group-hover:bg-black/40 transition-colors flex items-center justify-center opacity-0 group-hover:opacity-100">
                    <button
                      onClick={() => openQuickView(part)}
                      className="bg-white text-gray-900 p-3 rounded-full transform -translate-y-4 group-hover:translate-y-0 transition-transform"
                    >
                      <Eye size={20} />
                    </button>
                  </div>
                </div>
                <div className="p-4">
                  <h3 className="font-medium text-gray-900 mb-2">{part.name}</h3>
                  <p className="text-gray-500 text-sm mb-4">{part.description}</p>
                  <div className="flex items-center justify-between">
                    <span className="text-lg font-bold text-gray-900">${part.price.toFixed(2)}</span>
                    <button className="px-4 py-2 bg-[#cf2e2e] text-white rounded hover:bg-[#cf2e2e]/90 transition-colors">
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Load More */}
          <div className="text-center mt-12">
            <button className="px-6 py-3 bg-gray-900 text-white rounded-lg hover:bg-gray-800 transition-colors">
              Load More Parts
            </button>
          </div>
        </div>
      </div>

      {/* Quick View Modal */}
      {isQuickViewOpen && selectedPart && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg max-w-4xl w-full mx-4 max-h-[90vh] overflow-y-auto">
            <div className="p-6">
              <div className="flex justify-between items-start mb-6">
                <h3 className="text-2xl font-bold text-gray-900">{selectedPart.name}</h3>
                <button
                  onClick={() => setIsQuickViewOpen(false)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <X size={24} />
                </button>
              </div>
              <div className="grid md:grid-cols-2 gap-8">
                <div className="aspect-square relative rounded-lg overflow-hidden">
                  <img
                    src={selectedPart.image}
                    alt={selectedPart.name}
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                </div>
                <div>
                  <p className="text-gray-600 mb-4">{selectedPart.description}</p>
                  <p className="text-3xl font-bold text-gray-900 mb-6">
                    ${selectedPart.price.toFixed(2)}
                  </p>
                  <button className="w-full px-6 py-3 bg-[#cf2e2e] text-white rounded-lg hover:bg-[#cf2e2e]/90 transition-colors">
                    Add to Cart
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
}

export default Parts;