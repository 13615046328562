import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';

// Map path segments to readable names with descriptions for SEO
const pathNames: Record<string, { name: string; description?: string }> = {
  shop: { 
    name: 'Shop',
    description: 'Browse our collection of motorcycle parts and gear'
  },
  categories: { 
    name: 'Categories',
    description: 'All product categories'
  },
  category: { 
    name: 'Category'
  },
  parts: { 
    name: 'Parts',
    description: 'Motorcycle parts and components'
  },
  apparel: {
    name: 'Apparel',
    description: 'Motorcycle gear and clothing'
  },
  new: { 
    name: 'New Arrivals',
    description: 'Latest products'
  },
  sale: { 
    name: 'Sale',
    description: 'Discounted items'
  }
};

function Breadcrumbs() {
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);

  // Skip rendering if we're on the home page
  if (pathSegments.length === 0) return null;

  // Build breadcrumb items with proper labels and URLs
  const breadcrumbs = pathSegments.map((segment, index) => {
    // Build the URL for this breadcrumb
    const url = `/${pathSegments.slice(0, index + 1).join('/')}`;
    
    // Get the display label
    const pathInfo = pathNames[segment] || { 
      name: segment.charAt(0).toUpperCase() + segment.slice(1).replace(/-/g, ' ')
    };
    let label = pathInfo.name;
    
    // Special handling for category and product paths
    if (segment === 'category' && index < pathSegments.length - 1) {
      label = 'Category';
    } else if (index > 0 && pathSegments[index - 1] === 'category') {
      label = segment.charAt(0).toUpperCase() + segment.slice(1).replace(/-/g, ' ');
    } else if (index > 0 && pathSegments[index - 1] === 'product') {
      // For product pages, show a generic "Product Details" label
      label = 'Product Details';
    }

    return { 
      label, 
      url, 
      description: pathInfo.description,
      isLast: index === pathSegments.length - 1 
    };
  });

  // Filter out null values and ensure proper breadcrumb chain
  const filteredBreadcrumbs = breadcrumbs.filter(Boolean);
  return (
    <nav 
      className="flex flex-wrap items-center text-sm md:text-base" 
      aria-label="Breadcrumb"
    >
      {/* Schema.org BreadcrumbList markup */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": window.location.origin
            },
            ...filteredBreadcrumbs.map((breadcrumb, index) => ({
              "@type": "ListItem",
              "position": index + 2,
              "name": breadcrumb.label,
              "item": window.location.origin + breadcrumb.url
            }))
          ]
        })}
      </script>

      <ol 
        className="flex flex-wrap items-center space-x-2" 
        role="list"
      >
        <li className="flex items-center">
          <Link 
            to="/" 
            className="text-gray-500 hover:text-[#C3A15F] transition-colors focus:outline-none focus:ring-2 focus:ring-[#C3A15F] focus:ring-offset-2 rounded"
            aria-label="Home"
          >
            Home
          </Link>
        </li>

        {filteredBreadcrumbs.map((breadcrumb, index) => (
          <li 
            key={breadcrumb.url} 
            className="flex items-center"
            aria-current={breadcrumb.isLast ? 'page' : undefined}
          >
            <ChevronRight 
              className="w-4 h-4 text-gray-400 mx-2 flex-shrink-0" 
              aria-hidden="true"
            />
            {breadcrumb.isLast ? (
              <span 
                className="text-gray-900 font-medium truncate max-w-[200px]"
                title={breadcrumb.label}
              >
                {breadcrumb.label}
              </span>
            ) : (
              <Link
                to={breadcrumb.url}
                className="text-gray-500 hover:text-[#C3A15F] transition-colors focus:outline-none focus:ring-2 focus:ring-[#C3A15F] focus:ring-offset-2 rounded truncate max-w-[200px]"
                title={breadcrumb.description || breadcrumb.label}
              >
                {breadcrumb.label}
              </Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}

export default Breadcrumbs;