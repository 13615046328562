import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, X, ShoppingBag } from 'lucide-react';
import Footer from '../components/Footer';
import Breadcrumbs from '../components/Breadcrumbs';

const categories = [
  {
    id: 'mens',
    name: "Men's Collection",
    description: 'Premium motorcycle apparel for men',
    image: 'https://images.unsplash.com/photo-1600185365483-26d7a4cc7519?auto=format&fit=crop&q=80&w=1450',
  },
  {
    id: 'womens',
    name: "Women's Collection",
    description: 'Stylish motorcycle gear for women',
    image: 'https://images.unsplash.com/photo-1508424757105-b6d5ad9329d0?auto=format&fit=crop&q=80&w=1450',
  },
];

function Apparel() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navigation */}
      <nav className="fixed w-full bg-white shadow-sm z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-20">
            <div className="flex items-center h-full py-2">
              <Link to="/" className="w-[200px]">
                <img
                  src="https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/2nd%20Amendment_shiny_horizontal-03.svg"
                  alt="2nd Amendment Cycles"
                  className="w-full h-full object-contain logo-glow"
                />
              </Link>
            </div>
            
            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-8">
              <Link to="/shop" className="text-gray-900 hover:text-[#C3A15F] transition-colors">
                Shop All
              </Link>
              <Link to="/shop/apparel/mens" className="text-gray-900 hover:text-[#C3A15F] transition-colors">
                Men's
              </Link>
              <Link to="/shop/apparel/womens" className="text-gray-900 hover:text-[#C3A15F] transition-colors">
                Women's
              </Link>
              <Link to="/shop/new" className="text-gray-900 hover:text-[#C3A15F] transition-colors">
                New Arrivals
              </Link>
              <Link to="/shop/sale" className="text-[#cf2e2e] hover:text-[#cf2e2e]/80 transition-colors">
                Sale
              </Link>
              <button className="relative p-2">
                <ShoppingBag className="w-6 h-6 text-gray-900" />
                <span className="absolute top-0 right-0 -mt-1 -mr-1 bg-[#cf2e2e] text-white text-xs w-5 h-5 rounded-full flex items-center justify-center">
                  0
                </span>
              </button>
            </div>

            {/* Mobile menu button */}
            <div className="md:hidden flex items-center space-x-4">
              <button className="relative p-2">
                <ShoppingBag className="w-6 h-6 text-gray-900" />
                <span className="absolute top-0 right-0 -mt-1 -mr-1 bg-[#cf2e2e] text-white text-xs w-5 h-5 rounded-full flex items-center justify-center">
                  0
                </span>
              </button>
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="text-gray-900"
              >
                {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="md:hidden bg-white border-t">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <Link
                to="/shop"
                className="block px-3 py-2 text-gray-900 hover:bg-gray-50 hover:text-[#C3A15F]"
              >
                Shop All
              </Link>
              <Link
                to="/shop/apparel/mens"
                className="block px-3 py-2 text-gray-900 hover:bg-gray-50 hover:text-[#C3A15F]"
              >
                Men's
              </Link>
              <Link
                to="/shop/apparel/womens"
                className="block px-3 py-2 text-gray-900 hover:bg-gray-50 hover:text-[#C3A15F]"
              >
                Women's
              </Link>
              <Link
                to="/shop/new"
                className="block px-3 py-2 text-gray-900 hover:bg-gray-50 hover:text-[#C3A15F]"
              >
                New Arrivals
              </Link>
              <Link
                to="/shop/sale"
                className="block px-3 py-2 text-[#cf2e2e] hover:bg-gray-50"
              >
                Sale
              </Link>
            </div>
          </div>
        )}
      </nav>

      {/* Main Content */}
      <div className="pt-24">
        {/* Breadcrumbs */}
        <div className="bg-white border-b">
          <div className="max-w-7xl mx-auto px-4 py-4">
            <Breadcrumbs />
          </div>
        </div>

        {/* Hero Section */}
        <div className="bg-gray-900 text-white py-16">
          <div className="max-w-7xl mx-auto px-4">
            <h1 className="text-4xl md:text-5xl font-bold mb-4">Motorcycle Apparel</h1>
            <p className="text-gray-300 text-lg max-w-2xl">
              Premium riding gear and casual wear for motorcycle enthusiasts. Quality materials, 
              superior comfort, and authentic style.
            </p>
          </div>
        </div>

        {/* Categories Grid */}
        <div className="max-w-7xl mx-auto px-4 py-12">
          <div className="grid md:grid-cols-2 gap-8">
            {categories.map((category) => (
              <Link
                key={category.id}
                to={`/shop/apparel/${category.id}`}
                className="group relative overflow-hidden rounded-lg aspect-[16/9]"
              >
                <img
                  src={category.image}
                  alt={category.name}
                  className="absolute inset-0 w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                />
                <div className="absolute inset-0 bg-black/50 transition-opacity group-hover:bg-black/60">
                  <div className="absolute bottom-0 left-0 p-8">
                    <h3 className="text-white text-3xl font-bold mb-2">{category.name}</h3>
                    <p className="text-gray-200 text-lg">{category.description}</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Apparel;