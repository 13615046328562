import React from 'react';
import { Link } from 'react-router-dom';
import { Facebook, Mail, Phone, MapPin } from 'lucide-react';

function Footer() {
  return (
    <footer className="bg-black py-16">
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid md:grid-cols-3 gap-12">
          {/* Logo Column */}
          <div className="flex flex-col items-center md:items-start space-y-6">
            <div className="w-[200px]">
              <img
                src="https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/logo.png"
                alt="2nd Amendment Cycles"
                className="w-full h-auto object-contain"
              />
            </div>
            <p className="text-gray-400 text-center md:text-left">
              Engineered for the Uncompromising Rider: Premium Parts, Unrivaled Performance, Veteran-Supported
            </p>
            <div className="inline-block">
              <a
                href="https://www.facebook.com/profile.php?id=100063476230972"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-[#C3A15F] transition-colors flex items-center gap-2"
              >
                <Facebook size={20} />
                <span>Follow us on Facebook</span>
              </a>
            </div>
          </div>
          
          {/* Navigation Links */}
          <div className="flex flex-col items-center md:items-start">
            <h3 className="text-white font-bold mb-6 text-xl">Navigation</h3>
            <div className="flex flex-col items-center md:items-start space-y-4">
              <Link to="/" className="block text-gray-400 hover:text-[#C3A15F] transition-colors">
                Home
              </Link>
              <a href="#about" className="block text-gray-400 hover:text-[#C3A15F] transition-colors">
                About
              </a>
              <a href="#mission" className="block text-gray-400 hover:text-[#C3A15F] transition-colors">
                Mission
              </a>
              <a href="#merch" className="block text-gray-400 hover:text-[#C3A15F] transition-colors">
                Merch
              </a>
            </div>
          </div>
          
          {/* Contact Info */}
          <div className="flex flex-col items-center md:items-start">
            <h3 className="text-white font-bold mb-6 text-xl">Contact Us</h3>
            <div className="flex flex-col items-center md:items-start space-y-4 text-gray-400">
              <div className="flex items-center space-x-3">
                <MapPin size={20} className="text-[#C3A15F]" />
                <div>
                  <p>4556 McAshton St</p>
                  <p>Sarasota, FL 34233</p>
                </div>
              </div>
              <div className="flex items-center space-x-3">
                <Phone size={20} className="text-[#C3A15F]" />
                <p>(941) 920-7685</p>
              </div>
              <div className="flex items-center space-x-3">
                <Mail size={20} className="text-[#C3A15F]" />
                <p>info@2ndamendmentcycles.com</p>
              </div>
              <Link 
                to="/privacy-policy" 
                className="block text-gray-400 hover:text-[#C3A15F] transition-colors mt-6"
              >
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-800 pt-8 mt-8 text-center">
          <p className="text-gray-400 text-sm">
            © {new Date().getFullYear()} 2nd Amendment Cycles. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;