import React from 'react';
import { Menu, X, Shield, LogOut } from 'lucide-react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';

function Admin() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  // If authenticated, show admin dashboard
  return (
    <div className="min-h-screen bg-gray-50">
      <nav className="fixed w-full bg-black/90 backdrop-blur-sm z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-20">
            <div className="flex items-center h-full py-2">
              <div className="w-[350px]">
                <img
                  src="https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/2nd%20Amendment_shiny_horizontal-03.svg"
                  alt="2nd Amendment Cycles"
                  className="w-full h-full object-contain logo-glow"
                />
              </div>
            </div>
              
            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-8">
              <Link to="/" style={{ color: '#fff' }} className="hover:text-[#C3A15F] transition-colors">HOME</Link>
              <Link 
                to="/" 
                onClick={() => {
                  setTimeout(() => {
                    document.getElementById('about')?.scrollIntoView({ behavior: 'smooth' });
                  }, 100);
                }}
                style={{ color: '#fff' }} 
                className="hover:text-[#C3A15F] transition-colors"
              >
                ABOUT
              </Link>
              <Link 
                to="/" 
                onClick={() => {
                  setTimeout(() => {
                    document.getElementById('mission')?.scrollIntoView({ behavior: 'smooth' });
                  }, 100);
                }}
                style={{ color: '#fff' }} 
                className="hover:text-[#C3A15F] transition-colors"
              >
                MISSION
              </Link>
              <Link 
                to="/" 
                onClick={() => {
                  setTimeout(() => {
                    document.getElementById('merch')?.scrollIntoView({ behavior: 'smooth' });
                  }, 100);
                }}
                style={{ color: '#fff' }} 
                className="hover:text-[#C3A15F] transition-colors"
              >
                MERCH
              </Link>
              <a 
                href="https://asset.dragspecialties.com/static/sites/flippers/2025-Drag-Specialties-FatBook/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ backgroundColor: '#cf2e2e' }}
                className="px-6 py-2 rounded hover:opacity-90 transition-opacity"
              >
                PARTS CATALOG
              </a>
            </div>

            {/* Mobile menu button */}
            <div className="md:hidden flex items-center space-x-4">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="text-white hover:text-[#C3A15F]"
              >
                {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <Link to="/" className="block px-3 py-2 hover:text-[#C3A15F]">HOME</Link>
              <Link 
                to="/" 
                onClick={() => {
                  setIsMenuOpen(false);
                  setTimeout(() => {
                    document.getElementById('about')?.scrollIntoView({ behavior: 'smooth' });
                  }, 100);
                }}
                className="block px-3 py-2 hover:text-[#C3A15F]"
              >
                ABOUT
              </Link>
              <Link 
                to="/" 
                onClick={() => {
                  setIsMenuOpen(false);
                  setTimeout(() => {
                    document.getElementById('mission')?.scrollIntoView({ behavior: 'smooth' });
                  }, 100);
                }}
                className="block px-3 py-2 hover:text-[#C3A15F]"
              >
                MISSION
              </Link>
              <Link 
                to="/" 
                onClick={() => {
                  setIsMenuOpen(false);
                  setTimeout(() => {
                    document.getElementById('merch')?.scrollIntoView({ behavior: 'smooth' });
                  }, 100);
                }}
                className="block px-3 py-2 hover:text-[#C3A15F]"
              >
                MERCH
              </Link>
              <a
                href="https://asset.dragspecialties.com/static/sites/flippers/2025-Drag-Specialties-FatBook/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ backgroundColor: '#cf2e2e' }}
                className="block w-full text-left px-3 py-2 rounded hover:opacity-90"
              >
                PARTS CATALOG
              </a>
            </div>
          </div>
        )}
      </nav>

      <div className="pt-32 pb-12">
        <div className="max-w-7xl mx-auto px-4 space-y-8">
          <div className="flex justify-between items-center">
            <h1 className="text-4xl font-bold text-gray-900">Shop Management</h1>
            <div className="flex space-x-4">
              <button className="px-4 py-2 bg-[#C3A15F] text-white rounded-lg hover:bg-[#C3A15F]/90 transition-colors">
                Add Product
              </button>
              <button className="px-4 py-2 bg-[#cf2e2e] text-white rounded-lg hover:bg-[#cf2e2e]/90 transition-colors">
                Bulk Actions
              </button>
            </div>
          </div>
          
          {/* Quick Stats */}
          <div className="grid gap-6 md:grid-cols-4">
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-gray-500 text-sm font-medium">Total Products</h3>
              <p className="text-3xl font-bold text-gray-900 mt-2">128</p>
              <span className="text-green-600 text-sm mt-2 inline-block">↑ 12% from last month</span>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-gray-500 text-sm font-medium">Active Categories</h3>
              <p className="text-3xl font-bold text-gray-900 mt-2">12</p>
              <span className="text-gray-600 text-sm mt-2 inline-block">No change</span>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-gray-500 text-sm font-medium">Out of Stock</h3>
              <p className="text-3xl font-bold text-gray-900 mt-2">5</p>
              <span className="text-red-600 text-sm mt-2 inline-block">↑ 2 since yesterday</span>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-gray-500 text-sm font-medium">Total Sales</h3>
              <p className="text-3xl font-bold text-gray-900 mt-2">$12,450</p>
              <span className="text-green-600 text-sm mt-2 inline-block">↑ 8% this week</span>
            </div>
          </div>

          {/* Shop Management Tools */}
          <div className="grid gap-6 md:grid-cols-2">
            {/* Quick Links */}
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h2 className="text-xl font-bold text-gray-900 mb-4">Quick Links</h2>
              <div className="grid grid-cols-2 gap-4">
                <Link to="/shop/categories" className="p-4 border rounded-lg hover:bg-gray-50 transition-colors">
                  <h3 className="font-medium text-gray-900">Categories Overview</h3>
                  <p className="text-sm text-gray-500">Manage all product categories</p>
                </Link>
                <Link to="/shop" className="p-4 border rounded-lg hover:bg-gray-50 transition-colors">
                  <h3 className="font-medium text-gray-900">All Products</h3>
                  <p className="text-sm text-gray-500">View complete inventory</p>
                </Link>
                <Link to="/shop/new" className="p-4 border rounded-lg hover:bg-gray-50 transition-colors">
                  <h3 className="font-medium text-gray-900">New Arrivals</h3>
                  <p className="text-sm text-gray-500">Latest products</p>
                </Link>
                <Link to="/shop/sale" className="p-4 border rounded-lg hover:bg-gray-50 transition-colors">
                  <h3 className="font-medium text-gray-900">Sale Items</h3>
                  <p className="text-sm text-gray-500">Manage promotions</p>
                </Link>
              </div>
            </div>
            
            {/* Recent Activity */}
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h2 className="text-xl font-bold text-gray-900 mb-4">Recent Activity</h2>
              <div className="space-y-4">
                <div className="flex items-center justify-between py-2 border-b">
                  <div>
                    <p className="text-gray-900">New product added</p>
                    <p className="text-sm text-gray-500">Custom Chrome Handlebar</p>
                  </div>
                  <span className="text-sm text-gray-500">2 mins ago</span>
                </div>
                <div className="flex items-center justify-between py-2 border-b">
                  <div>
                    <p className="text-gray-900">Category updated</p>
                    <p className="text-sm text-gray-500">Accessories renamed</p>
                  </div>
                  <span className="text-sm text-gray-500">1 hour ago</span>
                </div>
                <div className="flex items-center justify-between py-2 border-b">
                  <div>
                    <p className="text-gray-900">Inventory alert</p>
                    <p className="text-sm text-gray-500">Low stock: Leather Seats</p>
                  </div>
                  <span className="text-sm text-gray-500">3 hours ago</span>
                </div>
              </div>
            </div>
          </div>
          
          {/* Shop Navigation Links */}
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h2 className="text-xl font-bold text-gray-900 mb-4">Shop Navigation</h2>
            <div className="grid gap-6 md:grid-cols-3">
              <div className="space-y-4">
                <h3 className="font-medium text-gray-900">Main Categories</h3>
                <ul className="space-y-2">
                  <li><Link to="/shop/category/air-intake" className="block text-gray-600 hover:text-[#C3A15F]">Air Intake & Gaskets</Link></li>
                  <li><Link to="/shop/category/audio-video" className="block text-gray-600 hover:text-[#C3A15F]">Audio/Video</Link></li>
                  <li><Link to="/shop/category/drivetrain" className="block text-gray-600 hover:text-[#C3A15F]">Drivetrain</Link></li>
                  <li><Link to="/shop/category/electrical" className="block text-gray-600 hover:text-[#C3A15F]">Electrical & Gauges</Link></li>
                  <li><Link to="/shop/category/engine" className="block text-gray-600 hover:text-[#C3A15F]">Engine</Link></li>
                </ul>
              </div>
              <div className="space-y-4">
                <h3 className="font-medium text-gray-900">More Categories</h3>
                <ul className="space-y-2">
                  <li><Link to="/shop/category/fairings" className="block text-gray-600 hover:text-[#C3A15F]">Fairings & Body</Link></li>
                  <li><Link to="/shop/category/foot-controls" className="block text-gray-600 hover:text-[#C3A15F]">Foot Pegs & Controls</Link></li>
                  <li><Link to="/shop/category/fuel-air" className="block text-gray-600 hover:text-[#C3A15F]">Fuel & Air System</Link></li>
                  <li><Link to="/shop/category/lighting" className="block text-gray-600 hover:text-[#C3A15F]">Lighting</Link></li>
                  <li><Link to="/shop/category/suspension" className="block text-gray-600 hover:text-[#C3A15F]">Suspension & Frame</Link></li>
                </ul>
              </div>
              <div className="space-y-4">
                <h3 className="font-medium text-gray-900">Top Brands</h3>
                <ul className="space-y-2">
                  <li><Link to="/shop/brand/ebc" className="block text-gray-600 hover:text-[#C3A15F]">EBC</Link></li>
                  <li><Link to="/shop/brand/emgo" className="block text-gray-600 hover:text-[#C3A15F]">EMGO</Link></li>
                  <li><Link to="/shop/brand/puig" className="block text-gray-600 hover:text-[#C3A15F]">PUIG HI-TECH PARTS</Link></li>
                  <li><Link to="/shop/brand/rk" className="block text-gray-600 hover:text-[#C3A15F]">RK</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="mt-auto">
        <Footer />
      </div>
    </div>
  );
}

export default Admin;