import React from 'react';
import Footer from '../components/Footer';
import {
  Menu,
  X,
  Calendar,
  Clock,
  ChevronRight,
  Wrench,
  Locate,
  Ticket,
  Cog,
  Mail,
  Facebook,
  Instagram,
  Twitter,
} from 'lucide-react';

function Home() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  return (
    <div className="min-h-screen bg-black text-white">
      {/* Navigation */}
      <nav className="fixed w-full bg-black/90 backdrop-blur-sm z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-20">
            <div className="flex items-center h-full py-2">
              <div className="w-[350px]">
                <img
                  src="https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/2nd%20Amendment_shiny_horizontal-03.svg"
                  alt="2nd Amendment Cycles"
                  className="w-full h-full object-contain logo-glow"
                />
              </div>
            </div>
            
            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-8">
              <a href="#home" style={{ color: '#fff' }} className="hover:text-[#C3A15F] transition-colors">HOME</a>
              <a href="#about" style={{ color: '#fff' }} className="hover:text-[#C3A15F] transition-colors">ABOUT</a>
              <a href="#mission" style={{ color: '#fff' }} className="hover:text-[#C3A15F] transition-colors">MISSION</a>
              <a href="#merch" style={{ color: '#fff' }} className="hover:text-[#C3A15F] transition-colors">MERCH</a>
              <a 
                href="https://asset.dragspecialties.com/static/sites/flippers/2025-Drag-Specialties-FatBook/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ backgroundColor: '#cf2e2e' }}
                className="px-6 py-2 rounded hover:opacity-90 transition-opacity"
              >
                PARTS CATALOG
              </a>
            </div>

            {/* Mobile menu button */}
            <div className="md:hidden flex items-center">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="text-white hover:text-[#C3A15F]"
              >
                {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <a href="#home" className="block px-3 py-2 hover:text-[#C3A15F]">HOME</a>
              <a href="#about" className="block px-3 py-2 hover:text-[#C3A15F]">ABOUT</a>
              <a href="#mission" className="block px-3 py-2 hover:text-[#C3A15F]">MISSION</a>
              <a href="#merch" className="block px-3 py-2 hover:text-[#C3A15F]">MERCH</a>
              <a
                href="https://asset.dragspecialties.com/static/sites/flippers/2025-Drag-Specialties-FatBook/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ backgroundColor: '#cf2e2e' }}
                className="block w-full text-left px-3 py-2 rounded hover:opacity-90"
              >
                PARTS CATALOG
              </a>
            </div>
          </div>
        )}
      </nav>

      {/* Hero Section */}
      <section id="home" className="relative h-screen pt-20">
        <div className="absolute inset-0">
          <img
            src="https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/MOTORCYCLE-HERO.jpg"
            alt="Custom 2nd Amendment Cycles Motorcycle"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black/60 mix-blend-multiply" />
        </div>
        <div className="relative max-w-7xl mx-auto px-4 h-full flex items-center">
          <div className="max-w-3xl">
            <h1 className="text-5xl md:text-7xl font-bold mb-6 leading-tight">
               BROTHERS <span style={{ color: '#cf2e2e' }}>IN ARMS</span> CHARITABLE FOUNDATION
            </h1>
            <p className="text-xl mb-8 text-gray-300">
              Win a Custom Kory Souza Original motorcycle!
            </p>
            <a 
              href="https://viewstub.com/Brothers-in-Arms-Custom-Motorcycle-raffle-for-Veterans?fbclid=IwY2xjawH3qhtleHRuA2FlbQIxMQABHTZ5O-31jFcKlruZVDtLIv-sAm_5PCC9IoWVcpnl3AWDRaq6iSfCwhak9w_aem_tHVyc0d9UDXJLP_oyQUGVA"
              target="_blank"
              rel="noopener noreferrer"
              style={{ backgroundColor: '#cf2e2e' }}
              className="px-8 py-4 rounded text-lg font-bold btn-glow inline-flex items-center"
            >
              VIEW RAFFEL EVENT <ChevronRight className="ml-2" />
            </a>
            <div className="mt-8 flex justify-start">
              <img
                src="https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/Logo%20for%20website%20BIA.png"
                alt="Brothers in Arms Logo"
                className="h-20 object-contain logo-glow"
              />
            </div>
          </div>
        </div>
      </section>

      {/* About Section */}
      <section id="about" className="py-24 bg-gradient-to-b from-black to-gray-900">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-4xl font-bold mb-6">TICKETS <span style={{ color: '#C3A15F' }}>$50</span></h2>
              <p className="text-gray-300 mb-6">
                Only 500 tickets will be sold. After the 500th ticket is sold, the drawing will take place 
                and the winner will be notified. The drawing will take place at the offices of CPA Tax & 
                Consulting PA.
              </p>
              <p className="text-gray-300 mb-6">
                Help support our veterans and get your 1 in 500 chance to own this beautiful custom bike.
              </p>
              <div className="bg-blue-900/30 p-6 rounded-lg space-y-4">
                <div className="flex items-center text-gray-200 border-b border-blue-800/50 pb-4">
                  <Ticket size={24} style={{ color: '#cf2e2e' }} className="mr-2 flex-shrink-0" />
                  <span className="text-lg">Support Veterans with Every Ticket</span>
                </div>
                <div className="space-y-3 pt-2">
                  <div className="flex items-center text-gray-300">
                    <Calendar size={20} style={{ color: '#cf2e2e' }} className="mr-3 flex-shrink-0" />
                    <span>Saturday 1/25</span>
                  </div>
                  <div className="flex items-center text-gray-300">
                    <Clock size={20} style={{ color: '#cf2e2e' }} className="mr-3 flex-shrink-0" />
                    <span>2:00 PM to 6:00 PM (Drawing at 5:00 PM)</span>
                  </div>
                  <div className="flex items-center text-gray-300">
                    <Locate size={20} style={{ color: '#cf2e2e' }} className="mr-3 flex-shrink-0" />
                    <span>4556 McAshton St, Sarasota, FL 34233, USA</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative h-96">
              <img
                src="https://drive.google.com/thumbnail?id=1Ok5DEDLt2iwCL7eTM2lG6Utc8S7ssXpW&sz=w2000"
                alt="Custom Motorcycle Build"
                className="absolute inset-0 w-full h-full object-cover rounded-lg shadow-xl"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Mission Section */}
      <section id="mission" className="py-24 bg-[url('https://drive.google.com/thumbnail?id=1OZWBrYqft4OOK6sPQgwAdhkBZ3Lv3WOb&sz=w2000')] bg-fixed bg-cover bg-center relative">
        <div className="absolute inset-0 bg-black/80" />
        <div className="relative max-w-7xl mx-auto px-4">
          <div className="text-center max-w-3xl mx-auto">
            <Locate size={48} style={{ color: '#C3A15F' }} className="mx-auto mb-6" />
            <h2 className="text-4xl font-bold mb-6">OUR MISSION</h2>
            <p className="text-xl text-gray-300 mb-12">
              2nd Amendment Cycles is driven by a passion for motorcycles and a deep respect for America's veterans. 
              We offer high-quality V-twin parts at competitive prices while donating a portion of our proceeds 
              to support those who have served our country.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="p-6 bg-gray-900/50 rounded-lg">
                <h3 className="font-bold mb-2">RIDE WITH PURPOSE</h3>
                <p className="text-gray-400">
                  Feel the wind in your face knowing you're supporting a veteran with every purchase. 
                  We donate a portion of our proceeds to Brothers in Arms Charitable Foundation Inc., 
                  a 501c(3) charity dedicated to helping those who have served.
                </p>
              </div>
              <div className="p-6 bg-gray-900/50 rounded-lg">
                <h3 className="font-bold mb-2">HERO REWARDS</h3>
                <p className="text-gray-400">
                  Join our Hero Rewards program for special pricing and exclusive benefits. 
                  Your membership fees directly support Brothers in Arms Charitable Foundation Inc., 
                  providing valuable assistance to veterans.
                </p>
              </div>
              <div className="p-6 bg-gray-900/50 rounded-lg">
                <h3 className="font-bold mb-2">PASSION MEETS PATRIOTISM</h3>
                <p className="text-gray-400">
                  We combine a love for motorcycles with unwavering support for veterans. 
                  Enjoy competitive prices, excellent service, and the satisfaction of making 
                  a difference in veterans' lives.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Hero Rewards Section */}
      <section className="py-24 bg-gradient-to-b from-black to-gray-900">
        <div className="max-w-7xl mx-auto px-4">
          <div className="bg-gray-900/50 rounded-lg p-8 md:p-12">
            <div className="text-center mb-12">
              <h2 className="text-4xl font-bold mb-4">JOIN OUR HERO REWARDS PROGRAM</h2>
              <p className="text-xl text-gray-300">Supporting America's Veterans</p>
            </div>
            
            <div className="grid md:grid-cols-2 gap-12 items-start">
              <div>
                <p className="text-gray-300 mb-8 text-lg">
                  As a proud partner of Brothers in Arms Charitable Foundation Inc, we invite our military heroes 
                  to join our exclusive Hero Rewards Program. Your one-time $50 tax-deductible membership fee 
                  directly supports veterans in need through this 100% volunteer-operated charity.
                </p>
                
                <div className="bg-black/30 rounded-lg p-6 mb-8">
                  <h3 className="font-bold text-xl mb-4" style={{ color: '#C3A15F' }}>Immediate Member Benefits</h3>
                  <ul className="space-y-3 text-gray-300">
                    <li className="flex items-center">
                      <span className="w-2 h-2 bg-[#cf2e2e] rounded-full mr-3" />
                      FREE shipping on all orders
                    </li>
                    <li className="flex items-center">
                      <span className="w-2 h-2 bg-[#cf2e2e] rounded-full mr-3" />
                      10% discount on eligible products*
                    </li>
                    <li className="flex items-center">
                      <span className="w-2 h-2 bg-[#cf2e2e] rounded-full mr-3" />
                      Instant activation - no points to earn or waiting period
                    </li>
                  </ul>
                  <p className="text-sm text-gray-400 mt-4">*MAP Policy restrictions apply to select brands. See Discount Exclusions for details.</p>
                </div>
              </div>
              
              <div className="bg-black/30 rounded-lg p-6">
                <h3 className="font-bold text-xl mb-6" style={{ color: '#C3A15F' }}>How to Join</h3>
                <div className="space-y-6">
                  <div className="flex items-start">
                    <div className="w-8 h-8 rounded-full bg-[#cf2e2e] flex items-center justify-center flex-shrink-0 mt-1">
                      <span className="font-bold">1</span>
                    </div>
                    <div className="ml-4">
                      <p className="text-gray-300">Call Kirk at (941) 920-7685 and mention "Hero Rewards"</p>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <div className="w-8 h-8 rounded-full bg-[#cf2e2e] flex items-center justify-center flex-shrink-0 mt-1">
                      <span className="font-bold">2</span>
                    </div>
                    <div className="ml-4">
                      <p className="text-gray-300">Complete your $50 membership registration</p>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <div className="w-8 h-8 rounded-full bg-[#cf2e2e] flex items-center justify-center flex-shrink-0 mt-1">
                      <span className="font-bold">3</span>
                    </div>
                    <div className="ml-4">
                      <p className="text-gray-300">Start enjoying your benefits immediately</p>
                    </div>
                  </div>
                </div>
                
                
                <a
                  href="https://asset.dragspecialties.com/static/sites/flippers/2025-Drag-Specialties-FatBook/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-6 w-full bg-[#cf2e2e] text-white py-3 px-4 rounded flex items-center justify-center gap-2 btn-glow"
                >
                  <span className="font-bold">VIEW 2025 FATBOOK</span>
                </a>
                
                <div className="mt-8 pt-8 border-t border-gray-700">
                  <p className="text-gray-300 text-center">
                    Your contribution makes a difference: Every dollar of your membership fee goes directly 
                    to supporting veteran services through Brothers in Arms Charitable Foundation Inc.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Sign Up Section */}
      <section id="support" className="py-24 relative bg-black">
        <div className="absolute inset-0 bg-[url('https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/distressed-texture.jpg')] opacity-20"></div>
        <div className="absolute inset-0 bg-gradient-to-b from-black/0 via-[#cf2e2e]/30 to-black/80"></div>
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center max-w-3xl mx-auto relative">
            <Mail size={48} className="mx-auto mb-4" />
            <h2 className="text-4xl font-bold mb-4">SUPPORT OUR VETERANS</h2>
            <p className="text-xl mb-6">
              Join our community of proud military supporters!
            </p>
            <div className="bg-black/40 backdrop-blur-sm border border-white/10 rounded-lg p-6 mb-8 shadow-2xl">
              <ul className="space-y-3 text-left">
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-white rounded-full mr-3" />
                  First access to new product launches
                </li>
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-white rounded-full mr-3" />
                  Special military discounts and promotions
                </li>
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-white rounded-full mr-3" />
                  Updates on veteran-focused events
                </li>
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-white rounded-full mr-3" />
                  Impact stories from Brothers in Arms Charitable Foundation Inc.
                </li>
              </ul>
            </div>
            <p className="text-lg mb-8">
              Enter your email to make a difference in veterans' lives while staying informed about products you'll love.
            </p>
            {/* Form submission feedback */}
            <div id="form-feedback" className="hidden mb-6 py-3 px-4 rounded bg-white/10 text-lg font-bold">
              Thank you for your support! We'll keep you updated on our mission to help veterans.
            </div>
            <form
              className="flex flex-col md:flex-row gap-4 justify-center"
              data-klaviyo-form-id="veterans-support"
              onSubmit={(e) => {
                e.preventDefault();
                const email = (e.target as HTMLFormElement).email.value;
                const form = e.target as HTMLFormElement;
                const feedback = document.getElementById('form-feedback');

                console.log('Testing Klaviyo integration...');
                if (window.klaviyo) {
                  console.log('Klaviyo is loaded');
                  window.klaviyo.identify({
                    email: email,
                    source: 'Veterans Support Signup'
                  });
                  console.log('Identified user:', email);
                  window.klaviyo.track('Newsletter Subscription', {
                    source: 'Veterans Support Section'
                  });
                  console.log('Tracked event: Newsletter Subscription');
                } else {
                  console.log('Klaviyo is not loaded');
                }
                
                // Show thank you message and reset form
                if (feedback) {
                  feedback.classList.remove('hidden');
                  form.reset();
                }
              }}
            >
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                className="px-6 py-3 rounded bg-black/50 backdrop-blur-sm border border-white/20 focus:outline-none focus:border-white/50 text-white placeholder-white/50 w-full md:w-96"
                required
              />
              <button 
                type="submit"
                className="px-8 py-3 rounded bg-[#cf2e2e] font-bold shadow-lg btn-glow"
              >
                COUNT ME IN
              </button>
            </form>
          </div>
        </div>
      </section>

      {/* Merch Section */}
      <section id="merch" className="py-24 bg-gradient-to-b from-gray-900 to-black">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-12">
            <Cog size={48} style={{ color: '#cf2e2e' }} className="mx-auto mb-6" />
            <h2 className="text-4xl font-bold mb-6">GEAR UP</h2>
            <p className="text-xl text-gray-300 mb-4">Merch Shop Coming Soon</p>
            <p className="text-lg text-gray-400">Get ready for our exclusive collection of American-made apparel and accessories</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                title: "PREMIUM APPAREL",
                image: "https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/DSC_7414.jpg"
              },
              {
                title: "CUSTOM GEAR",
                image: "https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/DSC_7413.jpg"
              },
              {
                title: "EXCLUSIVE ACCESSORIES",
                image: "https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/DSC_7364.jpg"
              }
            ].map((item, index) => (
              <div key={index} className="group relative">
                <div className="relative h-96 w-full overflow-hidden rounded-lg">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="h-full w-full object-cover object-center"
                  />
                  <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
                    <span className="text-2xl font-bold text-white">Coming Soon</span>
                  </div>
                </div>
                <h3 className="mt-4 text-lg font-bold">{item.title}</h3>
                <p className="text-gray-400">Stay tuned for our upcoming collection</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Home;