import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, X, ShoppingBag, Filter, ChevronDown } from 'lucide-react';
import Footer from '../components/Footer';
import Breadcrumbs from '../components/Breadcrumbs';

// Demo categories
const categories = [
  {
    id: 'parts',
    name: 'Parts & Components',
    description: 'Essential motorcycle parts and components',
    image: 'https://images.unsplash.com/photo-1558981806-ec527fa84c39?auto=format&fit=crop&q=80&w=600',
  },
  {
    id: 'accessories',
    name: 'Accessories',
    description: 'Enhance your ride with premium accessories',
    image: 'https://images.unsplash.com/photo-1558981359-219d6364c9c8?auto=format&fit=crop&q=80&w=600',
  },
  {
    id: 'apparel',
    name: 'Apparel',
    description: 'Riding gear and casual wear',
    image: 'https://images.unsplash.com/photo-1591637333184-19aa84b3e01f?auto=format&fit=crop&q=80&w=600',
  },
];

function Shop() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navigation */}
      <nav className="fixed w-full bg-white shadow-sm z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-20">
            <div className="flex items-center h-full py-2">
              <Link to="/" className="w-[200px]">
                <img
                  src="https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/2nd%20Amendment_shiny_horizontal-03.svg"
                  alt="2nd Amendment Cycles"
                  className="w-full h-full object-contain logo-glow"
                />
              </Link>
            </div>
            
            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-8">
              <Link to="/shop" className="text-gray-900 hover:text-[#C3A15F] transition-colors font-medium">
                Shop All
              </Link>
              <div className="relative group">
                <button className="flex items-center space-x-1 text-gray-900 hover:text-[#C3A15F] transition-colors font-medium">
                  <span>Categories</span>
                  <ChevronDown size={16} />
                </button>
                <div className="absolute top-full left-0 w-48 bg-white shadow-lg rounded-lg py-2 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all">
                  {categories.map((category) => (
                    <Link
                      key={category.id}
                      to={`/shop/category/${category.id}`}
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-50 hover:text-[#C3A15F]"
                    >
                      {category.name}
                    </Link>
                  ))}
                </div>
              </div>
              <Link to="/shop/new" className="text-gray-900 hover:text-[#C3A15F] transition-colors font-medium">
                New Arrivals
              </Link>
              <Link to="/shop/sale" className="text-[#cf2e2e] hover:text-[#cf2e2e]/80 transition-colors font-medium">
                Sale
              </Link>
              <button className="relative p-2">
                <ShoppingBag className="w-6 h-6 text-gray-900" />
                <span className="absolute top-0 right-0 -mt-1 -mr-1 bg-[#cf2e2e] text-white text-xs w-5 h-5 rounded-full flex items-center justify-center">
                  0
                </span>
              </button>
            </div>

            {/* Mobile menu button */}
            <div className="md:hidden flex items-center space-x-4">
              <button className="relative p-2">
                <ShoppingBag className="w-6 h-6 text-gray-900" />
                <span className="absolute top-0 right-0 -mt-1 -mr-1 bg-[#cf2e2e] text-white text-xs w-5 h-5 rounded-full flex items-center justify-center">
                  0
                </span>
              </button>
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="text-gray-900"
              >
                {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="md:hidden bg-white border-t">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <Link
                to="/shop"
                className="block px-3 py-2 text-gray-900 hover:bg-gray-50 hover:text-[#C3A15F]"
              >
                Shop All
              </Link>
              {categories.map((category) => (
                <Link
                  key={category.id}
                  to={`/shop/category/${category.id}`}
                  className="block px-3 py-2 text-gray-900 hover:bg-gray-50 hover:text-[#C3A15F]"
                >
                  {category.name}
                </Link>
              ))}
              <Link
                to="/shop/new"
                className="block px-3 py-2 text-gray-900 hover:bg-gray-50 hover:text-[#C3A15F]"
              >
                New Arrivals
              </Link>
              <Link
                to="/shop/sale"
                className="block px-3 py-2 text-[#cf2e2e] hover:bg-gray-50"
              >
                Sale
              </Link>
            </div>
          </div>
        )}
      </nav>

      {/* Main Content */}
      <div className="pt-24">
        {/* Breadcrumbs */}
        <div className="bg-white border-b">
          <div className="max-w-7xl mx-auto px-4 py-4">
            <Breadcrumbs />
          </div>
        </div>

        {/* Hero Section */}
        <div className="relative text-white py-16 overflow-hidden min-h-[400px]">
          <div className="absolute inset-0">
            <img
              src="https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/2ND_Amendment-Web-Page-Main-PhotO.gif"
              alt="2nd Amendment Cycles Background"
              className="w-full h-full object-cover object-top"
            />
            <div className="absolute inset-0 bg-black/60" />
          </div>
          <div className="relative max-w-7xl mx-auto px-4">
            <h1 className="relative text-4xl md:text-5xl font-bold mb-4">Shop Our Collection</h1>
            <p className="relative text-gray-300 text-lg max-w-2xl">
              Discover premium motorcycle parts, accessories, and apparel. Quality products for riders who demand the best.
            </p>
          </div>
        </div>

        {/* Categories Grid */}
        <div className="max-w-7xl mx-auto px-4 py-12">
          <div className="grid md:grid-cols-3 gap-8">
            {categories.map((category) => (
              <Link
                key={category.id}
                to={category.id === 'apparel' ? '/shop/apparel' : `/shop/category/${category.id}`}
                className="group relative overflow-hidden rounded-lg aspect-[4/3]"
              >
                <img
                  src={category.image}
                  alt={category.name}
                  className="absolute inset-0 w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                />
                <div className="absolute inset-0 bg-black/50 transition-opacity group-hover:bg-black/60">
                  <div className="absolute bottom-0 left-0 p-6">
                    <h3 className="text-white text-2xl font-bold mb-2">{category.name}</h3>
                    <p className="text-gray-200">{category.description}</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>

        {/* Hero Rewards CTA */}
        <div className="bg-gray-900 py-16 mt-12">
          <div className="max-w-7xl mx-auto px-4">
            <div className="grid md:grid-cols-2 gap-12 items-center">
              <div>
                <h2 className="text-3xl font-bold text-white mb-6">Join Our Hero Rewards Program</h2>
                <p className="text-gray-300 mb-8">
                  Support America's veterans while enjoying exclusive benefits. Your one-time $50 
                  tax-deductible membership fee directly supports Brothers in Arms Charitable Foundation Inc, 
                  a 100% volunteer-operated charity.
                </p>
                <div className="bg-black/30 rounded-lg p-6 mb-8">
                  <h3 className="font-bold text-xl mb-4 text-[#C3A15F]">Member Benefits</h3>
                  <ul className="space-y-4 text-gray-300">
                    <li className="flex items-center">
                      <span className="w-2 h-2 bg-[#cf2e2e] rounded-full mr-3" />
                      FREE shipping on all orders
                    </li>
                    <li className="flex items-center">
                      <span className="w-2 h-2 bg-[#cf2e2e] rounded-full mr-3" />
                      10% discount on eligible products
                    </li>
                    <li className="flex items-center">
                      <span className="w-2 h-2 bg-[#cf2e2e] rounded-full mr-3" />
                      Instant activation - no waiting period
                    </li>
                    <li className="flex items-center">
                      <span className="w-2 h-2 bg-[#cf2e2e] rounded-full mr-3" />
                      100% of membership fee supports veterans
                    </li>
                  </ul>
                </div>
                <button className="bg-[#cf2e2e] text-white px-8 py-4 rounded-lg hover:bg-[#cf2e2e]/90 transition-colors font-bold">
                  Join Hero Rewards Today
                </button>
              </div>
              <div className="relative">
                <img
                  src="https://images.unsplash.com/photo-1533105079780-92b9be482077?auto=format&fit=crop&q=80&w=1000"
                  alt="Veteran Support"
                  className="rounded-lg shadow-2xl"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-transparent to-transparent rounded-lg" />
                <img
                  src="https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/Logo%20for%20website%20BIA.png"
                  alt="Brothers in Arms Logo"
                  className="absolute bottom-6 left-6 h-16 logo-glow"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Featured Products */}
        <div className="bg-white py-12">
          <div className="max-w-7xl mx-auto px-4">
            <h2 className="text-3xl font-bold mb-8">Featured Products</h2>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
              {/* Product cards will go here */}
              <div className="text-center p-8 border rounded-lg">
                <p className="text-gray-500">Products Coming Soon</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Shop;