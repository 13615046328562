import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Admin from './pages/Admin';
import CategoryOverview from './pages/CategoryOverview';
import Shop from './pages/Shop';
import Parts from './pages/Parts';
import Apparel from './pages/Apparel';
import ApparelCategory from './pages/ApparelCategory';
import ProductPage from './pages/ProductPage';
import { CartProvider } from './contexts/CartContext';
import CartSlider from './components/CartSlider';
import MainNav from './components/MainNav';

function App() {
  return (
    <CartProvider>
      <MainNav />
      <CartSlider />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/shop/categories" element={<CategoryOverview />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/shop/category/:categoryId" element={<Shop />} />
        <Route path="/shop/parts" element={<Parts />} />
        <Route path="/shop/apparel" element={<Apparel />} />
        <Route path="/shop/apparel/:category" element={<ApparelCategory />} />
        <Route path="/shop/product/:productId" element={<ProductPage />} />
        <Route path="/shop/new" element={<Shop />} />
        <Route path="/shop/sale" element={<Shop />} />
      </Routes>
    </CartProvider>
  );
}

export default App;