import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  Menu, 
  X, 
  ShoppingBag, 
  ChevronRight, 
  ChevronLeft,
  Star,
  Truck,
  CreditCard,
  Shield,
  Info,
  Plus,
  Minus,
  Heart
} from 'lucide-react';
import Footer from '../components/Footer';
import Breadcrumbs from '../components/Breadcrumbs';

// Demo product data
const product = {
  id: 'p1',
  name: 'Premium Chrome Handlebar',
  price: 299.99,
  rating: 4.5,
  reviewCount: 128,
  availability: 'In Stock',
  sku: 'HB-2024-CR',
  images: [
    'https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/DSC_7414.jpg',
    'https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/DSC_7413.jpg',
    'https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/DSC_7364.jpg',
  ],
  description: `
    High-quality chrome handlebar designed for maximum comfort and style. Features:
    • Premium chrome finish
    • Ergonomic design
    • Universal mounting system
    • Weather-resistant coating
  `,
  specs: {
    material: 'Chrome-plated steel',
    width: '32 inches',
    rise: '6 inches',
    weight: '3.2 lbs',
    finish: 'Polished chrome',
    warranty: '2 years',
  },
  compatibility: [
    'Harley-Davidson Sportster (2015-2024)',
    'Harley-Davidson Softail (2018-2024)',
    'Indian Scout (2020-2024)',
  ],
  installationDifficulty: 'Moderate',
};

// Related products
const relatedProducts = [
  {
    id: 'rp1',
    name: 'Handlebar Grips',
    price: 49.99,
    image: 'https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/DSC_7414.jpg',
  },
  {
    id: 'rp2',
    name: 'Brake Lines Kit',
    price: 89.99,
    image: 'https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/DSC_7413.jpg',
  },
  {
    id: 'rp3',
    name: 'Clutch Cable',
    price: 39.99,
    image: 'https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/DSC_7364.jpg',
  },
];

function ProductPage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });

  const handleZoom = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isZoomed) return;
    
    const rect = e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;
    
    setZoomPosition({ x, y });
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navigation */}
      <nav className="fixed w-full bg-white shadow-sm z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-20">
            <div className="flex items-center">
              <Link to="/" className="w-[200px]">
                <img
                  src="https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/Logos-for-shine.gif"
                  alt="2nd Amendment Cycles"
                  className="w-full h-full object-contain"
                />
              </Link>
            </div>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-8">
              <Link to="/shop" className="text-gray-900 hover:text-[#C3A15F]">Shop All</Link>
              <Link to="/shop/new" className="text-gray-900 hover:text-[#C3A15F]">New Arrivals</Link>
              <Link to="/shop/sale" className="text-[#cf2e2e] hover:text-[#cf2e2e]/80">Sale</Link>
              <button className="relative">
                <ShoppingBag className="w-6 h-6 text-gray-900" />
                <span className="absolute -top-1 -right-1 bg-[#cf2e2e] text-white text-xs w-5 h-5 rounded-full flex items-center justify-center">
                  0
                </span>
              </button>
            </div>

            {/* Mobile menu button */}
            <div className="md:hidden flex items-center">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="text-gray-900"
              >
                {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <div className="pt-20">
        {/* Breadcrumbs */}
        <div className="bg-white border-b">
          <div className="max-w-7xl mx-auto px-4 py-4">
            <Breadcrumbs />
          </div>
        </div>

        {/* Product Section */}
        <div className="max-w-7xl mx-auto px-4 py-12">
          <div className="grid md:grid-cols-2 gap-12">
            {/* Image Gallery */}
            <div className="space-y-4">
              <div 
                className="relative aspect-square rounded-lg overflow-hidden bg-gray-100"
                onMouseEnter={() => setIsZoomed(true)}
                onMouseLeave={() => setIsZoomed(false)}
                onMouseMove={handleZoom}
              >
                <img
                  src={product.images[currentImage]}
                  alt={product.name}
                  className={`w-full h-full object-cover transition-transform duration-200 ${
                    isZoomed ? 'scale-150' : 'scale-100'
                  }`}
                  style={isZoomed ? {
                    transformOrigin: `${zoomPosition.x}% ${zoomPosition.y}%`
                  } : undefined}
                />
              </div>
              <div className="grid grid-cols-4 gap-4">
                {product.images.map((image, index) => (
                  <button
                    key={index}
                    onClick={() => setCurrentImage(index)}
                    className={`aspect-square rounded-lg overflow-hidden ${
                      currentImage === index ? 'ring-2 ring-[#C3A15F]' : ''
                    }`}
                  >
                    <img
                      src={image}
                      alt={`${product.name} view ${index + 1}`}
                      className="w-full h-full object-cover"
                    />
                  </button>
                ))}
              </div>
            </div>

            {/* Product Info */}
            <div>
              <div className="mb-6">
                <h1 className="text-3xl font-bold text-gray-900 mb-2">{product.name}</h1>
                <div className="flex items-center space-x-4">
                  <div className="flex items-center">
                    {[...Array(5)].map((_, i) => (
                      <Star
                        key={i}
                        size={20}
                        className={i < Math.floor(product.rating) ? 'text-yellow-400' : 'text-gray-300'}
                        fill={i < Math.floor(product.rating) ? 'currentColor' : 'none'}
                      />
                    ))}
                  </div>
                  <span className="text-gray-600">{product.reviewCount} reviews</span>
                </div>
              </div>

              <div className="mb-8">
                <p className="text-3xl font-bold text-gray-900">${product.price.toFixed(2)}</p>
                <p className="text-sm text-gray-500 mt-1">Free shipping on orders over $100</p>
              </div>

              {/* Availability */}
              <div className="mb-8">
                <div className="flex items-center space-x-2 text-green-600">
                  <span className="w-2 h-2 bg-green-600 rounded-full" />
                  <span>{product.availability}</span>
                </div>
                <p className="text-sm text-gray-500 mt-1">SKU: {product.sku}</p>
              </div>

              {/* Add to Cart */}
              <div className="mb-8">
                <div className="flex items-center space-x-4 mb-4">
                  <div className="flex items-center border rounded-lg">
                    <button
                      onClick={() => setQuantity(Math.max(1, quantity - 1))}
                      className="p-2 hover:bg-gray-50"
                    >
                      <Minus size={20} />
                    </button>
                    <input
                      type="number"
                      min="1"
                      value={quantity}
                      onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value) || 1))}
                      className="w-16 text-center border-x p-2"
                    />
                    <button
                      onClick={() => setQuantity(quantity + 1)}
                      className="p-2 hover:bg-gray-50"
                    >
                      <Plus size={20} />
                    </button>
                  </div>
                  <button className="flex-1 bg-[#cf2e2e] text-white py-3 px-6 rounded-lg hover:bg-[#cf2e2e]/90 transition-colors">
                    Add to Cart
                  </button>
                  <button className="p-3 border rounded-lg hover:bg-gray-50">
                    <Heart size={24} />
                  </button>
                </div>
                
                {/* Financing Options */}
                <div className="bg-gray-50 p-4 rounded-lg">
                  <div className="flex items-center space-x-2 mb-2">
                    <CreditCard size={20} className="text-gray-600" />
                    <span className="font-medium">Financing Available</span>
                  </div>
                  <p className="text-sm text-gray-600">
                    As low as $27/month with Affirm. <a href="#" className="text-[#C3A15F]">Learn more</a>
                  </p>
                </div>
              </div>

              {/* Trust Badges */}
              <div className="grid grid-cols-2 gap-4 mb-8">
                <div className="flex items-center space-x-3 p-4 bg-gray-50 rounded-lg">
                  <Truck size={24} className="text-gray-600" />
                  <div>
                    <p className="font-medium">Free Shipping</p>
                    <p className="text-sm text-gray-600">On orders over $100</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3 p-4 bg-gray-50 rounded-lg">
                  <Shield size={24} className="text-gray-600" />
                  <div>
                    <p className="font-medium">2-Year Warranty</p>
                    <p className="text-sm text-gray-600">Manufacturer backed</p>
                  </div>
                </div>
              </div>

              {/* Product Description */}
              <div className="prose prose-sm max-w-none mb-8">
                <h2 className="text-xl font-bold mb-4">Description</h2>
                <p className="whitespace-pre-line">{product.description}</p>
              </div>

              {/* Technical Specs */}
              <div className="mb-8">
                <h2 className="text-xl font-bold mb-4">Technical Specifications</h2>
                <div className="grid grid-cols-2 gap-4">
                  {Object.entries(product.specs).map(([key, value]) => (
                    <div key={key} className="flex items-center space-x-2">
                      <Info size={16} className="text-gray-400" />
                      <span className="text-gray-600">{key}:</span>
                      <span className="font-medium">{value}</span>
                    </div>
                  ))}
                </div>
              </div>

              {/* Compatibility */}
              <div>
                <h2 className="text-xl font-bold mb-4">Vehicle Compatibility</h2>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <ul className="space-y-2">
                    {product.compatibility.map((vehicle, index) => (
                      <li key={index} className="flex items-center space-x-2">
                        <ChevronRight size={16} className="text-[#C3A15F]" />
                        <span>{vehicle}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* Related Products */}
          <div className="mt-16">
            <h2 className="text-2xl font-bold mb-8">Recommended with this Part</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {relatedProducts.map((product) => (
                <div key={product.id} className="bg-white rounded-lg shadow-sm overflow-hidden">
                  <div className="aspect-square relative">
                    <img
                      src={product.image}
                      alt={product.name}
                      className="absolute inset-0 w-full h-full object-cover"
                    />
                  </div>
                  <div className="p-4">
                    <h3 className="font-medium text-gray-900 mb-2">{product.name}</h3>
                    <div className="flex items-center justify-between">
                      <span className="text-lg font-bold text-gray-900">
                        ${product.price.toFixed(2)}
                      </span>
                      <button className="px-4 py-2 bg-gray-900 text-white rounded hover:bg-gray-800 transition-colors">
                        Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ProductPage;