import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, X, Mail, Cog } from 'lucide-react';
import Footer from '../components/Footer';

function PrivacyPolicy() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  return (
    <div className="min-h-screen bg-black text-white">
      {/* Navigation */}
      <nav className="fixed w-full bg-black/90 backdrop-blur-sm z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-20">
            <div className="flex items-center h-full py-2">
              <div className="w-[350px]">
                <img
                  src="https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/Logos-for-shine.gif"
                  alt="2nd Amendment Cycles"
                  className="w-full h-full object-contain"
                />
              </div>
            </div>
            
            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-8">
              <Link to="/" style={{ color: '#fff' }} className="hover:text-[#C3A15F] transition-colors">HOME</Link>
              <Link 
                to="/" 
                onClick={() => {
                  setTimeout(() => {
                    document.getElementById('about')?.scrollIntoView({ behavior: 'smooth' });
                  }, 100);
                }}
                style={{ color: '#fff' }} 
                className="hover:text-[#C3A15F] transition-colors"
              >
                ABOUT
              </Link>
              <Link 
                to="/" 
                onClick={() => {
                  setTimeout(() => {
                    document.getElementById('mission')?.scrollIntoView({ behavior: 'smooth' });
                  }, 100);
                }}
                style={{ color: '#fff' }} 
                className="hover:text-[#C3A15F] transition-colors"
              >
                MISSION
              </Link>
              <Link 
                to="/" 
                onClick={() => {
                  setTimeout(() => {
                    document.getElementById('merch')?.scrollIntoView({ behavior: 'smooth' });
                  }, 100);
                }}
                style={{ color: '#fff' }} 
                className="hover:text-[#C3A15F] transition-colors"
              >
                MERCH
              </Link>
              <a 
                href="https://asset.dragspecialties.com/static/sites/flippers/2025-Drag-Specialties-FatBook/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ backgroundColor: '#cf2e2e' }}
                className="px-6 py-2 rounded hover:opacity-90 transition-opacity"
              >
                PARTS CATALOG
              </a>
            </div>

            {/* Mobile menu button */}
            <div className="md:hidden flex items-center">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="text-white hover:text-[#C3A15F]"
              >
                {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <Link to="/" className="block px-3 py-2 hover:text-[#C3A15F]">HOME</Link>
              <Link 
                to="/" 
                onClick={() => {
                  setIsMenuOpen(false);
                  setTimeout(() => {
                    document.getElementById('about')?.scrollIntoView({ behavior: 'smooth' });
                  }, 100);
                }}
                className="block px-3 py-2 hover:text-[#C3A15F]"
              >
                ABOUT
              </Link>
              <Link 
                to="/" 
                onClick={() => {
                  setIsMenuOpen(false);
                  setTimeout(() => {
                    document.getElementById('mission')?.scrollIntoView({ behavior: 'smooth' });
                  }, 100);
                }}
                className="block px-3 py-2 hover:text-[#C3A15F]"
              >
                MISSION
              </Link>
              <Link 
                to="/" 
                onClick={() => {
                  setIsMenuOpen(false);
                  setTimeout(() => {
                    document.getElementById('merch')?.scrollIntoView({ behavior: 'smooth' });
                  }, 100);
                }}
                className="block px-3 py-2 hover:text-[#C3A15F]"
              >
                MERCH
              </Link>
              <a
                href="https://asset.dragspecialties.com/static/sites/flippers/2025-Drag-Specialties-FatBook/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ backgroundColor: '#cf2e2e' }}
                className="block w-full text-left px-3 py-2 rounded hover:opacity-90"
              >
                PARTS CATALOG
              </a>
            </div>
          </div>
        )}
      </nav>

      <div className="max-w-4xl mx-auto px-4 py-24">
        <h1 className="text-4xl font-bold mb-8">Privacy Policy</h1>
        
        <div className="space-y-8 text-gray-300">
          <section>
            <h2 className="text-2xl font-bold mb-4 text-white">Introduction</h2>
            <p>
              2nd Amendment Cycles ("we," "our," or "us") respects your privacy and is committed to protecting 
              your personal information. This Privacy Policy explains how we collect, use, and safeguard your 
              information when you visit our website or use our services.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-bold mb-4 text-white">Information We Collect</h2>
            <div className="space-y-4">
              <p>We collect information that you provide directly to us, including:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Name and contact information</li>
                <li>Billing and shipping addresses</li>
                <li>Payment information</li>
                <li>Email address for newsletter subscriptions</li>
                <li>Information provided through our contact forms</li>
              </ul>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-bold mb-4 text-white">How We Use Your Information</h2>
            <div className="space-y-4">
              <p>We use the information we collect to:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Process your orders and payments</li>
                <li>Send you order confirmations and updates</li>
                <li>Provide customer support</li>
                <li>Send marketing communications (with your consent)</li>
                <li>Improve our website and services</li>
                <li>Comply with legal obligations</li>
              </ul>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-bold mb-4 text-white">Information Sharing</h2>
            <p>
              We do not sell, trade, or otherwise transfer your personal information to third parties without 
              your consent, except to trusted partners who assist us in operating our website, conducting our 
              business, or serving our users.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-bold mb-4 text-white">Data Security</h2>
            <p>
              We implement appropriate security measures to protect your personal information. However, no 
              method of transmission over the internet or electronic storage is 100% secure, and we cannot 
              guarantee absolute security.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-bold mb-4 text-white">Your Rights</h2>
            <div className="space-y-4">
              <p>You have the right to:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Access your personal information</li>
                <li>Correct inaccurate information</li>
                <li>Request deletion of your information</li>
                <li>Opt-out of marketing communications</li>
                <li>Withdraw consent where applicable</li>
              </ul>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-bold mb-4 text-white">Contact Us</h2>
            <p>
              If you have questions about this Privacy Policy or our privacy practices, please contact us at:
            </p>
            <div className="mt-4">
              <p>2nd Amendment Cycles</p>
              <p>4556 McAshton St</p>
              <p>Sarasota, FL 34233</p>
              <p>United States</p>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-bold mb-4 text-white">Updates to This Policy</h2>
            <p>
              We may update this Privacy Policy from time to time. The updated version will be indicated by an updated effective date. Your continued use of our website following the posting of changes constitutes your acceptance of such changes.
            </p>
          </section>
        </div>
      </div>
      
      {/* Footer */}
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;