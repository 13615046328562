import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight, Search, Filter, ArrowUpDown } from 'lucide-react';
import Footer from '../components/Footer';
import Breadcrumbs from '../components/Breadcrumbs';

// Category data
const categories = [
  {
    id: 'air-intake',
    name: 'Air Intake & Gaskets',
    image: 'https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/DSC_7414.jpg',
    count: 45
  },
  {
    id: 'audio-video',
    name: 'Audio/Video',
    image: 'https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/DSC_7413.jpg',
    count: 23
  },
  {
    id: 'drivetrain',
    name: 'Drivetrain',
    image: 'https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/DSC_7364.jpg',
    count: 67
  },
  {
    id: 'electrical',
    name: 'Electrical & Gauges',
    image: 'https://drive.google.com/thumbnail?id=1Ok5DEDLt2iwCL7eTM2lG6Utc8S7ssXpW&sz=w2000',
    count: 89
  },
  {
    id: 'engine',
    name: 'Engine',
    image: 'https://drive.google.com/thumbnail?id=1OZWBrYqft4OOK6sPQgwAdhkBZ3Lv3WOb&sz=w2000',
    count: 156
  }
];

const brands = ['EBC', 'EMGO', 'PUIG HI-TECH PARTS', 'RK'];

function CategoryOverview() {
  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="bg-white border-b">
        <div className="max-w-7xl mx-auto px-4 py-8">
          <Breadcrumbs />
          
          <div className="mt-8 flex flex-col md:flex-row md:items-center md:justify-between gap-4">
            <div>
              <h1 className="text-3xl font-bold text-gray-900">Motorcycle Parts & Accessories</h1>
              <p className="mt-2 text-gray-600">Browse our comprehensive selection of quality parts</p>
            </div>
            
            {/* Search and Filter */}
            <div className="flex gap-4">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search parts..."
                  className="pl-10 pr-4 py-2 border rounded-lg w-64 focus:outline-none focus:ring-2 focus:ring-[#C3A15F]"
                />
                <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
              </div>
              <button className="flex items-center gap-2 px-4 py-2 border rounded-lg hover:bg-gray-50">
                <Filter className="w-5 h-5" />
                <span>Filter</span>
              </button>
              <button className="flex items-center gap-2 px-4 py-2 border rounded-lg hover:bg-gray-50">
                <ArrowUpDown className="w-5 h-5" />
                <span>Sort</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 py-12">
        {/* Featured Categories Grid */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {categories.map((category) => (
            <Link
              key={category.id}
              to={`/shop/category/${category.id}`}
              className="group bg-white rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow"
            >
              <div className="aspect-[16/9] overflow-hidden">
                <img
                  src={category.image}
                  alt={category.name}
                  className="w-full h-full object-cover transform transition-transform group-hover:scale-105"
                />
              </div>
              <div className="p-6">
                <div className="flex items-center justify-between">
                  <h3 className="text-lg font-semibold text-gray-900">{category.name}</h3>
                  <span className="text-sm text-gray-500">{category.count} items</span>
                </div>
                <div className="mt-4 flex items-center text-[#C3A15F] font-medium">
                  <span>View Products</span>
                  <ChevronRight className="w-4 h-4 ml-1" />
                </div>
              </div>
            </Link>
          ))}
        </div>

        {/* Featured Brands */}
        <div className="mt-16">
          <h2 className="text-2xl font-bold text-gray-900 mb-8">Top Brands</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
            {brands.map((brand) => (
              <div
                key={brand}
                className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow text-center"
              >
                <h3 className="text-lg font-semibold text-gray-900">{brand}</h3>
                <p className="text-sm text-gray-500 mt-2">Premium Quality Parts</p>
              </div>
            ))}
          </div>
        </div>

        {/* Shopping Guide */}
        <div className="mt-16 bg-white rounded-lg shadow-sm p-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-6">Shopping Guide</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div>
              <h3 className="font-semibold text-gray-900 mb-2">Find the Right Part</h3>
              <p className="text-gray-600">
                Use our comprehensive catalog to find parts specifically designed for your motorcycle model.
              </p>
            </div>
            <div>
              <h3 className="font-semibold text-gray-900 mb-2">Quality Guarantee</h3>
              <p className="text-gray-600">
                All parts are sourced from trusted manufacturers and come with warranty coverage.
              </p>
            </div>
            <div>
              <h3 className="font-semibold text-gray-900 mb-2">Expert Support</h3>
              <p className="text-gray-600">
                Need help? Our team of motorcycle enthusiasts is here to assist you.
              </p>
            </div>
          </div>
        </div>

        {/* Newsletter */}
        <div className="mt-16 bg-gray-900 rounded-lg shadow-lg p-8 text-center">
          <h2 className="text-2xl font-bold text-white mb-4">Stay Updated</h2>
          <p className="text-gray-300 mb-6">
            Subscribe to receive updates about new products and special offers
          </p>
          <div className="max-w-md mx-auto flex gap-4">
            <input
              type="email"
              placeholder="Enter your email"
              className="flex-1 px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#C3A15F]"
            />
            <button className="px-6 py-2 bg-[#C3A15F] text-white rounded-lg hover:bg-[#C3A15F]/90 transition-colors">
              Subscribe
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default CategoryOverview;