import React from 'react'
import { Link } from 'react-router-dom'
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSeparator,
  MenubarShortcut,
  MenubarTrigger,
} from "@/components/ui/menubar"
import { ShoppingBag } from 'lucide-react'
import { useCart } from '../contexts/CartContext'

const MainNav = () => {
  const { toggleCart, items } = useCart()

  return (
    <nav className="fixed w-full bg-white shadow-sm z-50">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex items-center justify-between h-20">
          {/* Logo */}
          <Link to="/" className="w-[200px]">
            <img
              src="https://aitpfvmmhmjgzphlawmz.supabase.co/storage/v1/object/public/merch-images/2nd%20Amendment_shiny_horizontal-03.svg"
              alt="2nd Amendment Cycles"
              className="w-full h-full object-contain logo-glow"
            />
          </Link>

          {/* Main Navigation */}
          <Menubar className="hidden lg:flex border-none bg-transparent">
            <MenubarMenu>
              <MenubarTrigger className="font-medium">Shop</MenubarTrigger>
              <MenubarContent className="lg:w-[500px]">
                <div className="grid grid-cols-2 gap-4 p-4">
                  <div>
                    <h3 className="font-bold mb-2">Categories</h3>
                    <div className="space-y-2">
                      <MenubarItem>
                        <Link to="/shop/parts" className="w-full">Parts & Components</Link>
                      </MenubarItem>
                      <MenubarItem>
                        <Link to="/shop/apparel" className="w-full">Apparel</Link>
                      </MenubarItem>
                      <MenubarItem>
                        <Link to="/shop/accessories" className="w-full">Accessories</Link>
                      </MenubarItem>
                    </div>
                  </div>
                  <div>
                    <h3 className="font-bold mb-2">Featured</h3>
                    <div className="space-y-2">
                      <MenubarItem>
                        <Link to="/shop/new" className="w-full">New Arrivals</Link>
                      </MenubarItem>
                      <MenubarItem>
                        <Link to="/shop/sale" className="w-full">Sale Items</Link>
                      </MenubarItem>
                      <MenubarItem>
                        <Link to="/shop/best-sellers" className="w-full">Best Sellers</Link>
                      </MenubarItem>
                    </div>
                  </div>
                </div>
              </MenubarContent>
            </MenubarMenu>

            <MenubarMenu>
              <MenubarTrigger className="font-medium">Parts</MenubarTrigger>
              <MenubarContent className="lg:w-[700px]">
                <div className="grid grid-cols-3 gap-4 p-4">
                  <div>
                    <h3 className="font-bold mb-2">Engine & Performance</h3>
                    <div className="space-y-2">
                      <MenubarItem>
                        <Link to="/shop/category/engine" className="w-full">Engine Parts</Link>
                      </MenubarItem>
                      <MenubarItem>
                        <Link to="/shop/category/exhaust" className="w-full">Exhaust Systems</Link>
                      </MenubarItem>
                      <MenubarItem>
                        <Link to="/shop/category/air-intake" className="w-full">Air Intake</Link>
                      </MenubarItem>
                    </div>
                  </div>
                  <div>
                    <h3 className="font-bold mb-2">Body & Frame</h3>
                    <div className="space-y-2">
                      <MenubarItem>
                        <Link to="/shop/category/fairings" className="w-full">Fairings</Link>
                      </MenubarItem>
                      <MenubarItem>
                        <Link to="/shop/category/handlebars" className="w-full">Handlebars</Link>
                      </MenubarItem>
                      <MenubarItem>
                        <Link to="/shop/category/seats" className="w-full">Seats</Link>
                      </MenubarItem>
                    </div>
                  </div>
                  <div>
                    <h3 className="font-bold mb-2">Electrical</h3>
                    <div className="space-y-2">
                      <MenubarItem>
                        <Link to="/shop/category/lighting" className="w-full">Lighting</Link>
                      </MenubarItem>
                      <MenubarItem>
                        <Link to="/shop/category/batteries" className="w-full">Batteries</Link>
                      </MenubarItem>
                      <MenubarItem>
                        <Link to="/shop/category/electronics" className="w-full">Electronics</Link>
                      </MenubarItem>
                    </div>
                  </div>
                </div>
              </MenubarContent>
            </MenubarMenu>

            <MenubarMenu>
              <MenubarTrigger className="font-medium">Apparel</MenubarTrigger>
              <MenubarContent className="lg:w-[500px]">
                <div className="grid grid-cols-2 gap-4 p-4">
                  <div>
                    <h3 className="font-bold mb-2">Men's</h3>
                    <div className="space-y-2">
                      <MenubarItem>
                        <Link to="/shop/apparel/mens/jackets" className="w-full">Jackets</Link>
                      </MenubarItem>
                      <MenubarItem>
                        <Link to="/shop/apparel/mens/pants" className="w-full">Pants</Link>
                      </MenubarItem>
                      <MenubarItem>
                        <Link to="/shop/apparel/mens/boots" className="w-full">Boots</Link>
                      </MenubarItem>
                    </div>
                  </div>
                  <div>
                    <h3 className="font-bold mb-2">Women's</h3>
                    <div className="space-y-2">
                      <MenubarItem>
                        <Link to="/shop/apparel/womens/jackets" className="w-full">Jackets</Link>
                      </MenubarItem>
                      <MenubarItem>
                        <Link to="/shop/apparel/womens/pants" className="w-full">Pants</Link>
                      </MenubarItem>
                      <MenubarItem>
                        <Link to="/shop/apparel/womens/boots" className="w-full">Boots</Link>
                      </MenubarItem>
                    </div>
                  </div>
                </div>
              </MenubarContent>
            </MenubarMenu>

            <MenubarMenu>
              <MenubarTrigger className="font-medium">
                <Link to="/shop/sale" className="text-[#cf2e2e]">Sale</Link>
              </MenubarTrigger>
            </MenubarMenu>

            <MenubarMenu>
              <MenubarTrigger className="font-medium">
                <a
                  href="https://asset.dragspecialties.com/static/sites/flippers/2025-Drag-Specialties-FatBook/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-[#cf2e2e] text-white px-4 py-1 rounded hover:bg-[#cf2e2e]/90 transition-colors"
                >
                  Parts Catalog
                </a>
              </MenubarTrigger>
            </MenubarMenu>
          </Menubar>

          {/* Cart Button */}
          <button
            onClick={toggleCart}
            className="relative p-2"
            aria-label="Shopping Cart"
          >
            <ShoppingBag className="w-6 h-6 text-gray-900" />
            {items.length > 0 && (
              <span className="absolute -top-1 -right-1 bg-[#cf2e2e] text-white text-xs w-5 h-5 rounded-full flex items-center justify-center">
                {items.length}
              </span>
            )}
          </button>
        </div>
      </div>
    </nav>
  )
}

export default MainNav